import React, { useState } from "react";
import SelfForm from "../../components/Form/SelfForm";

const NominationSelfReEvaluation = () => {
  const [HRMID, setHRMID] = useState("");

  return (
    <SelfForm
      title="Self Nomination for Re-Evaluation"
      HRMID={HRMID}
      setHRMID={setHRMID}
      SheetName="Re_Evaluation"
    />
  );
};

export default NominationSelfReEvaluation;
