import React, { useState } from "react";
import SelfForm from "../../components/Form/SelfForm";

const NominationSelfProjectWarGames = () => {
  const [HRMID, setHRMID] = useState("");

  return (
    <SelfForm
      title="Self Nomination for Project War Games"
      HRMID={HRMID}
      setHRMID={setHRMID}
      SheetName="Project_War_Games"
    />
  );
};

export default NominationSelfProjectWarGames;
