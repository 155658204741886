import React, { useState } from "react";
import SelfForm from "../../components/Form/SelfForm";

const NominationSelfSQL = () => {
  const [HRMID, setHRMID] = useState("");

  return (
    <SelfForm
      title="Self Nomination for SQL Assessment"
      HRMID={HRMID}
      setHRMID={setHRMID}
      SheetName="SQL"
    />
  );
};

export default NominationSelfSQL;
