import { useEffect } from "react";
import useUserDetailHook from "../utils/useUserDetailHook";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { useMsal } from "@azure/msal-react";
import Footer from "../common/Footer";
import Navbar from "../common/Navbar";
import { sendMail } from "../utils/sendMail";
import { loginRequest } from "../authConfig";
import parikshakLogo from "../images/parikhshakLogo.jpg";
import "react-tooltip/dist/react-tooltip.css";
import styles from "../styles/Pages.module.css";

const Main = () => {
  const { instance, accounts } = useMsal();
  const [userEmailId, username] = useUserDetailHook();

  useEffect(() => {
    document.title = "Parikshak";
  }, []);

  const handleClick = (type) => {
    let subject = `[ Nomination for ${type} ${
      type !== "Project War Games" ? "Assessment " : ""
    }] Submission by - ${username}`;

    var body = `Hello,\n\n I ${username} nominate myself for ${type} ${
      type !== "Project War Games" ? "Assessment " : ""
    }.`;

    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        sendMail(subject, body, response.accessToken, userEmailId).then(
          (response) => {
            if (
              response &&
              (response.status === 202 || response.status === 200)
            ) {
              alert("Nomination Successfull!");
            }
          }
        );
      });
  };

  return (
    <>
      <Navbar />
      <div>
        <div className="container">
          <div className={styles.image_container}>
            <img src={parikshakLogo} className={styles.image} />
          </div>
          <div
            className="btn-container"
            style={{ marginBottom: "2rem", marginTop: "-2rem" }}
          >
            <div>
              <div className="button" style={{ marginTop: "3.75rem" }}>
                <Link
                  type="button"
                  className="btn btn-primary btn-lg"
                  rel="noreferrer"
                  id="self-sql"
                  to="/nomination-self-sql-assessment"
                >
                  Self Nomination for SQL Assessment
                </Link>
                <Tooltip
                  anchorId="self-sql"
                  content="If you want to get nominate for SQL Assessment, click on this button."
                />
              </div>
              <div className="button">
                <Link
                  type="button"
                  className="btn btn-primary btn-lg"
                  rel="noreferrer"
                  id="self-spark"
                  to="/nomination-self-spark-assessment"
                >
                  Self Nomination for Spark Assessment
                </Link>
                <Tooltip
                  anchorId="self-spark"
                  content="If you want to get nominate for Spark Assessment, click on this button."
                />
              </div>
              <div className="button">
                <Link
                  type="button"
                  className="btn btn-primary btn-lg"
                  rel="noreferrer"
                  id="self-databricks"
                  to="/nomination-self-databricks-assessment"
                >
                  Self Nomination for Databricks Assessment
                </Link>
                <Tooltip
                  anchorId="self-databricks"
                  content="If you want to get nominate for Databricks Assessment, click on this button."
                />
              </div>
              <div className="button">
                <Link
                  type="button"
                  className="btn btn-primary btn-lg"
                  rel="noreferrer"
                  id="self-project-war-games"
                  to="/nomination-self-project-war-games"
                >
                  Self Nomination for Project War Games
                </Link>
                <Tooltip
                  anchorId="self-project-war-games"
                  content="If you want to get nominate for Project War Games, click on this button."
                />
              </div>
              <div className="button">
                <Link
                  type="button"
                  className="btn btn-primary btn-lg"
                  rel="noreferrer"
                  id="self-onbench"
                  to="/nomination-self-onbench"
                >
                  Self Nomination For On Bench
                </Link>
                <Tooltip
                  anchorId="self-onbench"
                  content="If you want to get nominate for all three technologies, click on this button."
                />
              </div>
              <div className="button">
                <Link
                  type="button"
                  className="btn btn-primary btn-lg"
                  rel="noreferrer"
                  id="self-reevaluation"
                  to="/nomination-self-reevaluation"
                >
                  Self Nomination For Re-Evaluation
                </Link>
                <Tooltip
                  anchorId="self-reevaluation"
                  content="If you want to get nominate for re-evaluatione exam, click on this button."
                />
              </div>
            </div>
            <div>
              <div className="button">
                <Link
                  type="button"
                  className="btn btn-primary btn-lg"
                  to="/nomination-sql-assessment"
                  id="mentee-sql"
                >
                  Nomination of my Mentee/Downline for SQL Assessment
                </Link>
                <Tooltip
                  anchorId="mentee-sql"
                  content="Team members and RM can nominate their resource for SQL Assessment."
                />
              </div>
              <div className="button">
                <Link
                  type="button"
                  className="btn btn-primary btn-lg"
                  to="/nomination-spark-assessment"
                  id="mentee-spark"
                >
                  Nomination of my Mentee/Downline for Spark Assessment
                </Link>
                <Tooltip
                  anchorId="mentee-spark"
                  content="Team members and RM can nominate their resource for Spark Assessment."
                />
              </div>
              <div className="button">
                <Link
                  type="button"
                  className="btn btn-primary btn-lg"
                  to="/nomination-databricks-assessment"
                  id="mentee-databricks"
                >
                  Nomination of my Mentee/Downline for Databricks Assessment
                </Link>
                <Tooltip
                  anchorId="mentee-databricks"
                  content="Team members and RM can nominate their resource for Databricks Assessment."
                />
              </div>
              <div className="button">
                <Link
                  type="button"
                  className="btn btn-primary btn-lg"
                  to="/nomination-project-war-games"
                  id="mentee-project-war-games"
                >
                  Nomination of my Mentee/Downline for Project War Games
                </Link>
                <Tooltip
                  anchorId="mentee-project-war-games"
                  content="Team members and RM can nominate their resource for Project War Games."
                />
              </div>
              <div className="button">
                <Link
                  type="button"
                  className="btn btn-primary btn-lg"
                  to="/nomination-onbench"
                  id="mentee-onbench"
                >
                  Nomination of my Mentee/Downline for On Bench
                </Link>
                <Tooltip
                  anchorId="mentee-onbench"
                  content="Team members and RM can nominate their resource for all three technologies."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Main;
