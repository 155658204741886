import React from "react";
import { useMsal } from "@azure/msal-react";
import { Link } from "react-router-dom";
import ctlogo from "../images/ct-logo.jpg";
import Container from "react-bootstrap/Container";
import useUserDetailHook from "../utils/useUserDetailHook";
import styles from "../styles/Navbar.module.css";

const Navbar = () => {
  const { instance } = useMsal();
  const [userEmailId, username] = useUserDetailHook();

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  return (
    <nav className="navbar bg-body-tertiary bg-dark" data-bs-theme="dark">
      <Container fluid>
        <Link className="navbar-brand mx-4" to="/">
          <img src={ctlogo} alt="celebal-logo" width="150" height="50" />
          <p className={styles.title}>Parikshak ( The Evaluation Framework )</p>
        </Link>
        <div className={styles.avatar}>{username}</div>
        <button
          className={`btn-primary ${styles.logout_btn}`}
          onClick={handleLogout}
        >
          Logout
        </button>
      </Container>
    </nav>
  );
};

export default Navbar;
