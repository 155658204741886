import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../common/Navbar";
import Footer from "../../common/Footer";
import useUserDetailHook from "../../utils/useUserDetailHook";
import { sendMail } from "../../utils/sendMail";
import { loginRequest } from "../../authConfig";
import { useMsal } from "@azure/msal-react";
import axios from "axios";

const NominationSelfSQL = ({ title, HRMID, setHRMID, SheetName }) => {
  const { instance, accounts } = useMsal();
  const [userEmailId, username] = useUserDetailHook();

  useEffect(() => {
    document.title = title;
  }, []);

  const apiCall = () => {
    const data = {
      Name: username,
      HRMID: HRMID,
    };

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/updateExcel`,
        {
          data: data,
          sheetName: `${SheetName}`,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => console.log(error));
  };

  const submitHandler = (e) => {
    e.preventDefault();

    var body = `Hello,\n\n`;

    body = body + `Name: ${username}\nHRM ID: ${HRMID}\n\n`;

    let subject = `[${title}] Submission by - ${username}`;

    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        sendMail(subject, body, response.accessToken, userEmailId).then(
          (response) => {
            if (
              response &&
              (response.status === 202 || response.status === 200)
            ) {
              alert("Form data send Successfully!");
            }
          }
        );
      });

    apiCall();

    setHRMID("");
  };

  return (
    <>
      <Navbar />
      <div className="container" style={{ margin: "5rem auto" }}>
        <h3 style={{ marginBottom: "2rem" }}>{title}</h3>
        <form
          className="form"
          style={{ width: "690px" }}
          onSubmit={submitHandler}
        >
          <div>
            <div className="col-12">
              <label htmlFor="name">Name</label>
              <input
                name="name"
                value={username}
                type="text"
                className="form-control"
                id="name"
                placeholder="Name"
                disabled
              />
            </div>
            <div className="col-12">
              <label htmlFor="hrmid">HRM ID</label>
              <input
                name="HRMID"
                onChange={(e) => setHRMID(e.target.value)}
                value={HRMID}
                type="text"
                className="form-control"
                id="HRMID"
                placeholder="HRM ID"
                required
              />
            </div>
          </div>
          <div className="d-flex justify-content-sm-between mt-4">
            <Link
              to="/"
              className="btn btn-secondary"
              style={{ width: "25% !important" }}
            >
              Back
            </Link>
            <button
              type="submit"
              className="btn btn-primary"
              style={{ width: "25% !important" }}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default NominationSelfSQL;
