import Form from "../../components/Form/Form";

const NominationProjectWarGames = () => {
  return (
    <Form
      title="Nomination for Project War Games"
      SheetName="Project_War_Games"
    />
  );
};

export default NominationProjectWarGames;
