import { Route, Routes } from "react-router-dom";
import Login from "./components/LoginPage/LoginPage";
import PageNotFound from "./pages/PageNotFound";
import Main from "./pages/Main";
import NominationSelfSQL from "./pages/SQL/NominationSelfSQL";
import NominationSQL from "./pages/SQL/NominationSQL";
import NominationSelfSpark from "./pages/Spark/NominationSelfSpark";
import NominationSpark from "./pages/Spark/NominationSpark";
import NominationSelfDatabricks from "./pages/Databricks/NominationSelfDatabricks";
import NominationDatabricks from "./pages/Databricks/NominationDatabricks";
import NominationSelfProjetWarGames from "./pages/WarGames/NominationSelfProjectWarGames";
import NominationProjectWarGames from "./pages/WarGames/NominationProjectWarGames";
import NominationSelfOnBench from "./pages/OnBench/NominationSelfOnBench";
import NominationOnBench from "./pages/OnBench/NominationOnBench";
import NominationSelfReEvaluation from "./pages/ReEvaluation/NominationSelfReEvaluation";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import "./App.css";

function App() {
  return (
    <>
      <AuthenticatedTemplate>
        <div className="App">
          <Routes>
            <Route exact path="/" element={<Main />} />
            <Route
              path="/nomination-self-sql-assessment"
              exact
              element={<NominationSelfSQL />}
            />
            <Route
              path="/nomination-sql-assessment"
              exact
              element={<NominationSQL />}
            />
            <Route
              path="/nomination-self-spark-assessment"
              exact
              element={<NominationSelfSpark />}
            />
            <Route
              path="/nomination-spark-assessment"
              exact
              element={<NominationSpark />}
            />
            <Route
              path="/nomination-self-databricks-assessment"
              exact
              element={<NominationSelfDatabricks />}
            />
            <Route
              path="/nomination-databricks-assessment"
              exact
              element={<NominationDatabricks />}
            />
            <Route
              path="/nomination-self-project-war-games"
              exact
              element={<NominationSelfProjetWarGames />}
            />
            <Route
              path="/nomination-project-war-games"
              exact
              element={<NominationProjectWarGames />}
            />
            <Route
              path="/nomination-self-onbench"
              exact
              element={<NominationSelfOnBench />}
            />
            <Route
              path="/nomination-onbench"
              exact
              element={<NominationOnBench />}
            />
            <Route
              path="/nomination-self-reevaluation"
              exact
              element={<NominationSelfReEvaluation />}
            />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Routes>
          <Route path="/" exact element={<Login />} />
          <Route
            path="/nomination-self-sql-assessment"
            exact
            element={<Login />}
          />
          <Route path="/nomination-sql-assessment" exact element={<Login />} />
          <Route
            path="/nomination-self-spark-assessment"
            exact
            element={<Login />}
          />
          <Route
            path="/nomination-spark-assessment"
            exact
            element={<Login />}
          />
          <Route
            path="/nomination-self-databricks-assessment"
            exact
            element={<Login />}
          />
          <Route
            path="/nomination-databricks-assessment"
            exact
            element={<Login />}
          />
          <Route
            path="/nomination-self-project-war-games"
            exact
            element={<Login />}
          />
          <Route
            path="/nomination-project-war-games"
            exact
            element={<Login />}
          />
          <Route path="/nomination-self-onbench" exact element={<Login />} />
          <Route path="/nomination-onbench" exact element={<Login />} />
          <Route
            path="/nomination-self-reevaluation"
            exact
            element={<Login />}
          />
        </Routes>
      </UnauthenticatedTemplate>
    </>
  );
}

export default App;
