import { useEffect, useState } from "react";

const useUserDetailHook = () => {
  const [userEmailId, setUserEmailId] = useState("");
  const [username, setUsername] = useState("");
  useEffect(() => {
    Object.values(window.sessionStorage).map((sessiondata) => {
      if (sessiondata.includes("username")) {
        setUserEmailId(JSON.parse(sessiondata).username);
        setUsername(JSON.parse(sessiondata).name);
      }
    });
  }, []);

  return [userEmailId, username];
};

export default useUserDetailHook;
